import { format, parse } from 'date-fns';

export const getDateFromIso = (date: string): Date => {
    if (date) {
        return parse(date, 'yyyy-MM-dd', new Date());
    }

    return null;
};

export const getFormattedIsoDate = (date: Date): string => {
    if (date && !Number.isNaN(Number(date))) {
        return format(date, 'yyyy-MM-dd');
    }

    return null;
};
