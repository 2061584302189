import React, { useCallback } from 'react';

import { InputTag } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import {
    MultiSelectControlBase,
    UseField,
    MultiSelectType,
} from 'types/control-form';

interface MultiSelectControlProps extends MultiSelectControlBase {
    useField?: UseField;
}

export const MultiSelectControl: React.FC<MultiSelectControlProps> = ({
    ...props
}) => {
    const {
        type,
        id,
        placeholder,
        uniqueIdentifier,
        readonly,
        useField,
        options,
    } = props;

    const [field, , helpers] = useField(id);

    const { value } = field;
    const { setTouched, setValue } = helpers;

    const handleChange = useCallback(
        selected => {
            setValue({ ...value, values: selected });
        },
        [setValue, value]
    );

    if (type === MultiSelectType.DROPDOWN) {
        return (
            <InputTag
                {...field}
                options={options}
                placeholder={placeholder}
                value={value?.values || []}
                onBlur={() => setTouched(true)}
                onChange={(_, v) => handleChange(v)}
                disabled={readonly}
                disableCloseOnSelect
                textField={{
                    'data-cy': getDataAttribute(
                        '',
                        uniqueIdentifier,
                        'multi-select'
                    ),
                }}
            />
        );
    }

    return null;
};
