export const getDataAttribute = (
    prefix: string,
    text = '',
    postfix: string
): string => {
    const formattedText = text.toLowerCase?.().replaceAll?.(' ', '-');

    return prefix
        ? `${prefix}-${formattedText}-${postfix}`
        : `${formattedText}-${postfix}`;
};
