import {
    CandidateApplicationComponent,
    CollectionControlBase,
    CollectionItemType,
    ComponentRequestType,
    InputControlBase,
    ControlBase,
    ControlType,
    ControlTypeBase,
    MultiCheckboxControlBase,
    MultiValueComponent,
    SchemaComponent,
    SingleValueComponent,
    DocumentControlBase,
    MultiSelectControlBase,
    OutputControlBase,
    NoValueComponent,
} from 'types/control-form';

import { InitialValues } from '../types';

const getDefaultFieldInitialValue = ({
    id,
}:
    | ControlTypeBase
    | CandidateApplicationComponent
    | SchemaComponent): SingleValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.SingleValue,
    value: '',
});

const getCollectionInitialValue = ({
    id,
    type,
}: CollectionControlBase): SingleValueComponent => {
    if (type === CollectionItemType.CHECKBOX) {
        return {
            id,
            componentRequestType: ComponentRequestType.SingleValue,
            value: false,
        };
    }

    return getDefaultFieldInitialValue(<ControlTypeBase>{ id });
};

const getMultiInitialValues = ({
    id,
}:
    | MultiCheckboxControlBase
    | InputControlBase
    | MultiSelectControlBase): MultiValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.MultiValue,
    values: [],
});

const getDocumentUploaderInitialValues = ({
    id,
}: DocumentControlBase): MultiValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.DocumentUploader,
    values: [],
});

const getOutputInitialValues = ({
    id,
}: OutputControlBase): NoValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.NoValue,
});

const getInitialValue = (
    schemaComponent:
        | ControlTypeBase
        | CandidateApplicationComponent
        | SchemaComponent
) => {
    const { componentType } = schemaComponent;

    switch (componentType) {
        case ControlType.COLLECTION:
            return getCollectionInitialValue(
                <CollectionControlBase>schemaComponent
            );

        case ControlType.MULTI_CHECKBOX:
            return getMultiInitialValues(
                schemaComponent as unknown as MultiCheckboxControlBase
            );

        case ControlType.MULTI_INPUT:
            return getMultiInitialValues(
                schemaComponent as unknown as InputControlBase
            );

        case ControlType.UPLOAD:
            return {
                id: schemaComponent.id,
                componentRequestType: (schemaComponent as ControlBase)
                    .requestType,
            } as unknown as MultiValueComponent;

        case ControlType.MULTI_SELECT:
            return getMultiInitialValues(
                schemaComponent as unknown as MultiSelectControlBase
            );

        case ControlType.DOCUMENT_UPLOADER:
            return getDocumentUploaderInitialValues(
                schemaComponent as unknown as DocumentControlBase
            );

        case ControlType.OUTPUT:
            return getOutputInitialValues(
                schemaComponent as unknown as OutputControlBase
            );
        default:
            return getDefaultFieldInitialValue(schemaComponent);
    }
};

const getValuesFromSchema = (
    schemaComponents: ControlTypeBase[] // SchemaComponent[] |
) => {
    const initialValues: InitialValues = {};

    schemaComponents
        .filter(component => component.componentType !== ControlType.OUTPUT)
        .forEach(component => {
            if (component.enabled) {
                const name = component.id;

                initialValues[name] = getInitialValue(component);
            }
        });

    return initialValues;
};

export { getInitialValue, getValuesFromSchema };
