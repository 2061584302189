import React, { useCallback } from 'react';

import { FileUploader } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import readFileAsDataURL from 'helpers/read-file-as-data-url';
import {
    ComponentRequestType,
    FileControlBase,
    DynamicValues,
    UseFormikContext,
} from 'types/control-form';
import {
    ALL_EXTENSIONS,
    DOC_EXTENSIONS,
    PDF_EXTENSIONS,
} from 'types/file-uploader';

interface UploadControlProps extends FileControlBase {
    useFormikContext?: UseFormikContext;
    fieldName?: string;
    multiple?: boolean;
    download?: { url?: string; label?: string };
    gtmId?: string;
    dataTestId?: string;
    fileUpload?: (files: File[]) => Promise<unknown[]>;
}

const base64FileValueUpload = async (
    files: File[]
): Promise<
    {
        file: string;
        contentType: string;
        filename: string;
        length: number;
    }[]
> => {
    const promises = files.map(file =>
        readFileAsDataURL(file).then((data: any) => ({
            file: data?.split(',')[1],
            contentType: file?.type,
            filename: file?.name,
            length: file?.size,
        }))
    );

    return Promise.all(promises);
};

export const UploadControl: React.FC<UploadControlProps> = ({ ...props }) => {
    const {
        download,
        useFormikContext,
        uniqueIdentifier,
        id,
        dataTestId,
        fileUpload = base64FileValueUpload,
        multiple,
    } = props;

    const { setFieldValue, setStatus } = useFormikContext<DynamicValues>();

    const hasDownloadAddon = !!download;

    const onDrop = useCallback(
        async files => {
            const uploaded = files.filter(file => !file.id);

            const existed = files.filter(file => file.id).map(file => file.id);

            try {
                setStatus('WITH_UNLOADED_FILES');
                const result = await fileUpload(uploaded);

                setFieldValue(id, {
                    id,
                    componentRequestType: ComponentRequestType.FileRequest,
                    // not files to suit the contract https://app.swaggerhub.com/apis/Workmotion/OffboardingAPI/v1.0.18#/FileRequest
                    filesIds: [...existed, ...result],
                });

                setStatus('ALL_FILES_UPLOADED');
            } catch (error) {
                setStatus('FILE_UPLOADING_ERROR');
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },
        [fileUpload, id, setFieldValue, setStatus]
    );

    return (
        <FileUploader
            onDrop={onDrop}
            files={(props as any).value?.map(v => ({ ...v, name: v.fileName }))}
            maxSize={3145728}
            dataCy={getDataAttribute('', uniqueIdentifier, 'file_uploader')}
            isDesktop={false}
            fileExtension={
                hasDownloadAddon
                    ? { ...PDF_EXTENSIONS, ...DOC_EXTENSIONS }
                    : ALL_EXTENSIONS
            }
            dataTestId={dataTestId}
            onAcceptedFilesChange={files => onDrop(files)}
            multiple={multiple}
        />
    );
};
