import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const FileName = styled.p({
    color: palette.greyscale.persianBlue,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '320px',
    '@media (max-width: 480px) ': {
        width: '248px',
    },
});

export const StyledLineContainer = styled.div({
    marginTop: '1rem',
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
});
