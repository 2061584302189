const PDF_EXTENSIONS = ['application/pdf'];

const DOC_EXTENSIONS = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const XLS_EXTENSIONS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
];

const TEXT_EXTENSIONS = ['text/csv', 'text/plain'];

const IMAGES_EXTENSIONS = ['image/jpeg', 'image/png', 'image/svg'];

const FILES_EXTENSIONS = [
    ...PDF_EXTENSIONS,
    ...DOC_EXTENSIONS,
    ...XLS_EXTENSIONS,
    ...TEXT_EXTENSIONS,
];

const ALL_EXTENSIONS = [...IMAGES_EXTENSIONS, ...FILES_EXTENSIONS];

export {
    PDF_EXTENSIONS,
    DOC_EXTENSIONS,
    XLS_EXTENSIONS,
    TEXT_EXTENSIONS,
    IMAGES_EXTENSIONS,
    FILES_EXTENSIONS,
    ALL_EXTENSIONS,
};
