import React, { useEffect } from 'react';

import { SelectorField } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import { SwitchControlBase, UseField } from 'types/control-form';

interface SwitchControlProps extends SwitchControlBase {
    setEnablerValue: (updatedValue: boolean | string) => void;
    dataTestId?: string;
    useField?: UseField;
}

export const SwitchControl: React.FC<SwitchControlProps> = ({
    setEnablerValue,
    dataTestId,
    useField,
    ...props
}) => {
    const { truthyLabel, falsyLabel, uniqueIdentifier, id } = props;

    const [field, meta] = useField(id);

    const { value } = field;

    const isChecked = ['true', true, truthyLabel].includes(value?.value);

    const { onChange, ...fieldRestProps } = field;

    const hasError = !!(meta.touched && meta.error);

    useEffect(() => {
        if (value?.value === '') {
            setEnablerValue(false);
        }
    }, [value, setEnablerValue]);

    return (
        <SelectorField
            {...fieldRestProps}
            key={id}
            checkedTextProp={truthyLabel}
            unCheckedTextProp={falsyLabel}
            isChecked={isChecked}
            handleOnChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
            ) => {
                onChange(event);
                setEnablerValue(checked);
            }}
            data-testid={dataTestId}
            dataCy={{
                containerDataCy: getDataAttribute(
                    'steps',
                    uniqueIdentifier,
                    'switch'
                ),
            }}
            hasError={hasError}
        />
    );
};
