import React, { useState } from 'react';

import { Adornment } from './Adornment';
import { BasicInput } from '../BasicInput';
import { InputFieldsProps } from '../types';

export const PasswordInput: React.FC<InputFieldsProps> = ({
    type,
    adornmentPosition = 'start',
    id,
    ...props
}: InputFieldsProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <BasicInput
            {...props}
            inputType={showPassword ? 'text' : 'password'}
            InputProps={{
                startAdornment: adornmentPosition === 'start' && (
                    <Adornment
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        adornmentPosition="start"
                    />
                ),
                endAdornment: adornmentPosition === 'end' && (
                    <Adornment
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        adornmentPosition="end"
                    />
                ),
            }}
            id={id}
        />
    );
};
