import React, { useCallback, useEffect, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, DeleteForeverIcon, palette } from 'workmotion-design-system';

import { en } from 'localization';

import {
    convertDatesArrayToRanges,
    generateValuesArray,
    getDatesForTheRange,
    getFormattedDate,
} from './date-helper';
import { DateRangePicker } from './date-range-picker';
import { MultiPickerDateComponentProps } from './types';

export const MultiPickerDateComponent: React.FC<
    MultiPickerDateComponentProps
> = ({ onChange, value, hasError }) => {
    const [ranges, setRanges] = useState([]);

    useEffect(() => {
        setRanges(convertDatesArrayToRanges(value || []));
    }, [value]);

    const addNewDateRange = useCallback(() => {
        setRanges([...ranges, []]);
    }, [ranges]);

    const {
        multiInput: { addNewDatesBtn },
    } = en;

    const handleDateRangeChange = useCallback(
        (start, end, index) => {
            const formattedStartDate = getFormattedDate(start);
            const formattedEndDate = getFormattedDate(end);

            const newDateRanges = [...ranges];

            newDateRanges.splice(
                index,
                1,
                getDatesForTheRange(formattedStartDate, formattedEndDate)
            );

            onChange(generateValuesArray(newDateRanges));
        },
        [onChange, ranges]
    );

    const deleteDates = useCallback(
        index => {
            const newDateRanges = [...ranges];

            newDateRanges.splice(index, 1);

            onChange(generateValuesArray(newDateRanges));
        },
        [ranges, onChange]
    );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {ranges &&
                ranges.map((range, index) => (
                    <div
                        key={range[0]}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '0.75rem',
                        }}
                    >
                        <DateRangePicker
                            startDate={range[0] || null}
                            endDate={range[range.length - 1] || null}
                            onChange={(start: Date, end: Date) =>
                                handleDateRangeChange(start, end, index)
                            }
                            hasError={hasError}
                        />

                        {ranges.length > 1 && (
                            <DeleteForeverIcon
                                style={{
                                    marginLeft: '8px',
                                    color: palette.semantic.red,
                                    fontSize: '1rem',
                                }}
                                type="button"
                                onClick={() => deleteDates(index)}
                                data-testid="delete-btn"
                            />
                        )}
                    </div>
                ))}

            <div>
                <Button
                    size="small"
                    btnType="transparent"
                    onClick={addNewDateRange}
                    disabled={
                        ranges.length
                            ? ranges[ranges.length - 1].length === 0
                            : false
                    }
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            cursor: 'pointer',
                            gap: '0.5rem',
                        }}
                    >
                        <AddCircleIcon fontSize="small" />

                        {addNewDatesBtn}
                    </div>
                </Button>

                <div />
            </div>
        </div>
    );
};
