import React, { useCallback, useEffect, useState } from 'react';

import { DatePicker, InputField, TimePicker } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import { getDateFromIso, getFormattedIsoDate } from 'helpers/get-date-utils';
import { InputControlBase, InputType, UseField } from 'types/control-form';

interface InputControlProps extends InputControlBase {
    onBlur: () => void;
    componentEnabler: (componentType: string, value: string) => void;
    useField?: UseField;
}

export const InputControl: React.FC<InputControlProps> = ({
    componentEnabler,
    onBlur,
    useField,
    ...props
}) => {
    const {
        type,
        id,
        placeholder,
        minDate: minDateProp,
        maxDate: maxDateProp,
        uniqueIdentifier,
        dataTestId,
        constraints,
        disabled,
        readonly,
    } = props;

    const [minDate, setMinDate] = useState<number | string>(minDateProp);
    const [maxDate, setMaxDate] = useState<number | string>(maxDateProp);

    useEffect(() => {
        if (constraints?.length && constraints[0]?.min) {
            setMinDate(constraints[0]?.min);
        } else {
            setMinDate(minDateProp);
        }

        if (constraints?.length && constraints[0]?.max) {
            setMaxDate(constraints[0]?.max);
        } else {
            setMaxDate(maxDateProp);
        }
    }, [constraints, maxDateProp, minDateProp]);

    const [field, meta] = useField(id);

    const { value } = field;

    const hasError = !!(meta.touched && meta.error);

    const dateChange = useCallback(
        (date: Date) => {
            componentEnabler('single_value', getFormattedIsoDate(date) || '');
        },
        [componentEnabler]
    );

    if (type === InputType.DATE_ONLY) {
        return (
            <div data-testid={`${dataTestId}-dateOnly`}>
                <DatePicker
                    {...field}
                    key={id}
                    value={getDateFromIso(value?.value)}
                    onChange={dateChange}
                    minDate={minDate && new Date(minDate)}
                    maxDate={maxDate && new Date(maxDate)}
                    hasError={hasError}
                    dataCy={getDataAttribute(
                        '',
                        uniqueIdentifier,
                        'input-date-picker'
                    )}
                    placeholder="dd/mm/yyyy"
                    disabled={readonly}
                />
            </div>
        );
    }

    if (type === InputType.TIME_ONLY) {
        return (
            <div
                data-testid={`${dataTestId}-timeOnly`}
                data-cy={getDataAttribute(
                    '',
                    uniqueIdentifier,
                    'input-time-picker'
                )}
            >
                <TimePicker
                    {...field}
                    key={id}
                    value={value?.value}
                    onChange={time => componentEnabler('single_value', time)}
                    hasError={hasError}
                    placeholder="HH:MM"
                    disabled={readonly}
                />
            </div>
        );
    }

    return (
        <InputField
            {...field}
            value={value?.value || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                componentEnabler('single_value', event.target.value)
            }
            attrs={{
                input: {
                    'data-cy': getDataAttribute('', uniqueIdentifier, 'input'),
                    'data-testid': dataTestId,
                },
            }}
            onBlur={onBlur}
            type={type}
            placeholder={placeholder}
            key={id}
            showError={hasError}
            id={id}
            disabled={disabled}
        />
    );
};
