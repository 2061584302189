import { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const MultiCheckboxWrapper = styled.div({
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
    '& span': {
        color: palette.greyscale.UCLABlue,
    },
});

export const labelStyle: SxProps = {
    '& .MuiFormControlLabel-root': {
        width: '48.4%',
        marginBottom: '0 !important',

        '@media (max-width: 480px) ': {
            width: '100%',
            marginBottom: '0.75rem !important',
        },
    },
};
