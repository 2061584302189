import { formatISO, format } from 'date-fns';

/**
 *return date in forrmat 'yyyy-mm-dd'
 */
export const getFormattedDate = (date: Date): string => {
    if (date) {
        return String(format(new Date(date), 'yyyy-MM-dd'));
    }

    return null;
};

/**
 *return array of dates between start and end dates
 */

export const getDatesForTheRange = (start: string, end: string): any[] => {
    const startDate = start && new Date(start);

    const endDate = end && new Date(end);
    let dateValues = [];
    let currentDate = startDate;

    if (startDate === null && endDate === null) {
        return null;
    }

    if (startDate === null) {
        return [getFormattedDate(new Date(endDate))];
    }

    if (endDate === null) {
        return [getFormattedDate(new Date(startDate))];
    }

    while (currentDate <= endDate) {
        dateValues.push(getFormattedDate(new Date(currentDate)));

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateValues;
};

/**
 *return true if start date before or equal to enddate
 */
export const compareDates = (start: Date, end: Date): boolean => {
    if (start === null || end === null) return true;

    return start <= end;
};

/**
 * parse array with dates and generate a new array which consist of arrays for every daterange
 */

export const convertDatesArrayToRanges = (
    datesArray: string[]
): Array<string[]> => {
    if (datesArray.length) {
        return datesArray.reduce(
            (result, element, index, array) => {
                result[result.length - 1].push(element);

                const currentDay = new Date(element);

                const nextDay = array[index + 1]
                    ? new Date(array[index + 1])
                    : null;

                currentDay.setDate(currentDay.getDate() + 1);

                if (
                    nextDay &&
                    currentDay.toDateString() !== nextDay.toDateString()
                ) {
                    result.push([]);
                }

                return result;
            },
            [[]]
        );
    }

    return [[]];
};

/**
 *convert rangesArray to array with values for MultiInputcomponent
 */

export const generateValuesArray = (array: Array<string[]>): string[] => {
    const resArr = [];

    array
        .filter(item => !!item)
        .forEach(element => {
            resArr.push(...element);
        });

    return resArr;
};

/**
 *check if provided date is valid
 */

export const isValidIsoDate = (date: Date): boolean => {
    try {
        formatISO(date, {
            representation: 'date',
        });

        return new Date(date).getFullYear() >= 2022;
    } catch {
        return false;
    }
};
