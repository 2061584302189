import React, { useCallback, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import { DocumentUploader } from 'workmotion-design-system';

import { FilenamePreview } from 'design-elements/filename-preview';
import { getDataAttribute } from 'helpers/get-data-attribute';
import readFileAsDataURL from 'helpers/read-file-as-data-url';
import { DocumentControlBase, UseField } from 'types/control-form';
import { ALL_EXTENSIONS } from 'types/file-uploader';

interface DocumentUploadControlProps extends DocumentControlBase {
    useField?: UseField;
    fieldName?: string;
    multiple?: boolean;
    download?: { url?: string; label?: string };
    gtmId?: string;
    dataTestId?: string;
    fileUpload?: (files: File[]) => Promise<unknown[]>;
}

export const DocumentUploadControl: React.FC<DocumentUploadControlProps> = ({
    ...props
}) => {
    const {
        useField,
        uniqueIdentifier,
        id,
        multiple,
        filesCount: count = 2,
        uploaderTitle,
        uploaderDescription,
    } = props;

    const [{ value }, , { setValue }] = useField(id);

    const filesCount = useMemo(() => {
        if (multiple) return count;

        return 1;
    }, [count, multiple]);

    const getBase64String = base64FullString => {
        const commaIndex = base64FullString?.indexOf(',');

        if (commaIndex !== -1) {
            return base64FullString.substring(commaIndex + 1);
        }

        return base64FullString;
    };

    const onDrop = useCallback(
        async files => {
            if (files.length) {
                const base64FilesPromise = files.map(file =>
                    readFileAsDataURL(file).then(data => ({
                        data: getBase64String(data),
                        contentType: file.type,
                        fileName: file.name,
                    }))
                );

                return Promise.all(base64FilesPromise).then(result => {
                    if (result.length) {
                        let valuesArr = [];

                        if (value?.values?.length) {
                            valuesArr = [...value.values, ...result];
                        } else {
                            valuesArr = result;
                        }

                        if (valuesArr.length > filesCount) {
                            valuesArr = valuesArr.splice(
                                valuesArr.length - filesCount,
                                valuesArr.length
                            );
                        }

                        setValue({ ...value, values: valuesArr });
                    }
                });
            }

            return null;
        },
        [filesCount, setValue, value]
    );

    const onDeleteFile = (name: string) => {
        const newValues = value?.values.filter(file => {
            if (file?.fileName) {
                return file.fileName !== name;
            }

            return file.name !== name;
        });

        setValue({ ...value, values: newValues });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: ALL_EXTENSIONS as any,
    });

    return (
        <>
            <DocumentUploader
                rootProps={getRootProps()}
                inputProps={getInputProps()}
                title={uploaderTitle}
                description={uploaderDescription}
                required
                dataCy={{
                    documentUploader: getDataAttribute(
                        '',
                        uniqueIdentifier,
                        'document-uploader'
                    ),
                    uploaderInput: getDataAttribute(
                        '',
                        uniqueIdentifier,
                        'document-uploader-input'
                    ),
                }}
                attrs={{
                    type: 'button',
                }}
            />

            <FilenamePreview
                onDeleteFile={onDeleteFile}
                files={value?.values || []}
            />
        </>
    );
};
