import React, { useMemo } from 'react';

import { Box, InputAdornment } from '@mui/material';

import { BasicInput } from '../BasicInput';
import { adornmentStyles, unitStyles } from '../styled-components';
import { InputFieldsProps } from '../types';

export const UnitInput: React.FC<InputFieldsProps> = ({
    type,
    adornmentPosition,
    unit,
    ...props
}: InputFieldsProps) => {
    const Adornment = useMemo(
        () => (
            <InputAdornment position={adornmentPosition} sx={adornmentStyles}>
                <Box sx={unitStyles}>{unit}</Box>
            </InputAdornment>
        ),
        [adornmentPosition, unit]
    );

    return (
        <BasicInput
            {...props}
            inputType="number"
            InputProps={{
                startAdornment: adornmentPosition === 'start' && Adornment,
                endAdornment: adornmentPosition === 'end' && Adornment,
            }}
        />
    );
};
