import React from 'react';

import { Textarea } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import { TextEditorControlBase, UseField } from 'types/control-form';

interface TextAreaControlProps extends TextEditorControlBase {
    componentEnabler: (componentType: string, value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    useField?: UseField;
    dataTestId?: string;
}

export const TextAreaControl: React.FC<TextAreaControlProps> = ({
    componentEnabler,
    useField,
    onFocus,
    onBlur,
    dataTestId,
    ...props
}) => {
    const { id, placeholder, uniqueIdentifier } = props;

    const [field, meta] = useField(id);

    const { value } = field;

    const hasError = !!(meta.touched && meta.error);

    return (
        <Textarea
            {...field}
            style={{ maxWidth: '100%' }}
            value={value?.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                componentEnabler('single_value', event.target.value)
            }
            onFocus={onFocus}
            onBlur={onBlur}
            key={id}
            placeholder={placeholder}
            id={id}
            showError={hasError}
            data-testid={dataTestId}
            data-cy={getDataAttribute('', uniqueIdentifier, 'textarea')}
        />
    );
};
