import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';

import {
    CollectionControlBase,
    ControlType,
    ControlTypeBase,
} from 'types/control-form';

import { collectionValidation } from './collection';
import { defaultValidation } from './default';
import { documentUploader } from './document-uploader';
import { multiCheckboxValidation } from './multi-checkbox';
import { multiInputValidation } from './multi-input';
import { switchValidation } from './switch';
import { uploadValidation } from './upload';

const requiredValidation = function (
    this: TestContext<AnyObject>,
    required: boolean,
    componentType: ControlType,
    val,
    component: ControlTypeBase
) {
    if (!required) {
        return true;
    }

    switch (componentType) {
        case ControlType.SWITCH:
            return switchValidation.required();
        case ControlType.MULTI_INPUT:
            return multiInputValidation.required(val);
        case ControlType.MULTI_CHECKBOX:
            return multiCheckboxValidation.required(val);
        case ControlType.DOCUMENT_UPLOADER:
            return documentUploader.required(val);
        case ControlType.UPLOAD:
            return uploadValidation.required(val);
        case ControlType.COLLECTION:
            return collectionValidation.required.call(
                this,
                val,
                <CollectionControlBase>component
            );
        default:
            return defaultValidation.required(val);
    }
};

export { requiredValidation };
