/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DatePickerStyle } from './styled-components';
import { DatePickerProps } from './types';
import { MuiInputField } from '../InputField';

const DatePicker: React.FC<DatePickerProps> = ({
    value,
    onChange,
    hasError = false,
    hasNoMinDate = false,
    shouldDisplayDayCalendar = true,
    minDate = null,
    maxDate = null,
    onBlur,
    dataCy,
    iconDataCy,
    defaultCalendarMonth,
    disabled,
    id,
    ...restProps
}) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MUIDatePicker
            {...restProps}
            inputFormat={shouldDisplayDayCalendar ? 'dd/MM/yyyy' : 'MM/yyyy'}
            views={
                shouldDisplayDayCalendar ? ['year', 'day'] : ['month', 'year']
            }
            value={value}
            minDate={hasNoMinDate ? null : minDate}
            maxDate={maxDate}
            onChange={onChange}
            disabled={disabled}
            PaperProps={{ sx: DatePickerStyle }}
            DialogProps={{ sx: DatePickerStyle }}
            defaultCalendarMonth={defaultCalendarMonth}
            renderInput={params => (
                <MuiInputField
                    {...params}
                    attrs={{ input: { 'data-cy': dataCy } }}
                    type="text"
                    label=""
                    id={id}
                    helperText=""
                    inputProps={{ ...params.inputProps }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment
                                data-testid={iconDataCy}
                                position="end"
                            >
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        ),
                    }}
                    placeholder={params.InputProps.placeholder}
                    showError={hasError}
                />
            )}
        />
    </LocalizationProvider>
);

export { DatePicker };
