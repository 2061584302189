export const en = {
    control: {
        unsupportedFieldPlaceholder: 'Unsupported field',
    },

    validationMessages: {
        dynamicFields: {
            fieldRequired: 'This field is required',
            maxLength: 'Maximum length is 255 characters',
            minNumber: 'This value should be more or equal',
            maxNumber: 'This value should be less or equal',
            validNumber: 'This value is not a valid number',
            invalidValue: 'Invalid Value',
            textAreaMaxLength: 'Maximum length is 1024 characters',
            multInputMaxLength: 'Please add some dates',
        },
    },
    datePickers: {
        invalidDateError: 'Invalid Date',
        startEndDateCompareError: 'End Date should be after the start date',
    },
    multiInput: {
        addNewDatesBtn: 'Add new dates',
    },
};
