import { SxProps } from '@mui/material';
import { palette } from 'workmotion-design-system';

export const labelStyles: SxProps = {
    marginBottom: '0.75rem',
    "&[data-label-required='true']": {
        '&:after': {
            content: "'*'",
        },
    },
};

export const wrapperStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
};

export const inputStyles: SxProps = {
    '&.small': {
        width: '198px',
    },
    '&.medium': {
        width: '248px',
    },
    '&.large': {
        width: '412px',
        '@media (max-width: 480px) ': {
            width: '100%',
        },
    },
    '&.fullWidth': {
        width: '100%',
    },
    '& input': {
        padding: '11.5px 16px',
        fontSize: '0.75rem',
        /* Chrome, Safari, Edge, Opera */
        '::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },

        /* Firefox */
        '&[type=number]': {
            MozAppearance: 'textfield',
        },
    },
    '& .MuiInputAdornment-root': {
        marginLeft: 0,
    },
    '& .MuiIconButton-root': {
        color: palette.greyscale.sharkMouth,
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        color: palette.greyscale.persianBlue,
        backgroundColor: palette.greyscale.ghostWhite,
        fontSize: '0.75rem',
        '& fieldset': {
            borderColor: palette.greyscale.antiFlashWhite,
        },
        '&:hover fieldset': {
            border: `1px solid ${palette.primary.genericViridian} `,
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${palette.primary.genericViridian} `,
        },
        '&.Mui-error fieldset': {
            borderColor: palette.semantic.red,
        },
    },
    '& .MuiCheckbox-root': {
        padding: '0px',
    },
    '& .MuiFormHelperText-root': {
        marginLeft: '0px',
    },
};

export const errorMsgStyles: SxProps = {
    padding: 0,
    color: palette.semantic.red,
    fontSize: '0.75rem',
    marginTop: '8px',
};

export const helperTextStyles: SxProps = {
    padding: 0,
    color: palette.greyscale.UCLABlue,
    fontSize: '0.75rem',
    marginTop: '8px',
};

export const adornmentStyles: SxProps = {
    marginRight: 0,
};

export const adornmentColor: React.CSSProperties = {
    color: palette.greyscale.sharkMouth,
};

export const IconBtnStyles: SxProps = {
    color: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&.start': {
        paddingRight: 0,
    },
};

export const unitStyles: SxProps = {
    fontSize: '0.75rem',
};
