import React from 'react';

import styled from 'styled-components';
import { InfoTip, TipTypes, palette } from 'workmotion-design-system';

import { getDataAttribute } from 'helpers/get-data-attribute';
import { OutputControlBase, OutputType } from 'types/control-form';

const FieldsSeparator = styled.span({
    width: '100%',
    height: '1px',
    backgroundColor: palette.greyscale.antiFlashWhite,
});

const FieldTitle = styled.h2({
    width: '100%',
    fontWeight: 600,
    fontSize: '0.875rem',
    color: palette.primary.genericViridian,
    fontFamily: 'Work Sans',
});

export const OutputControl: React.FC<OutputControlBase> = ({ ...props }) => {
    const { uniqueIdentifier, dataTestId, type, title } = props;

    if (type === OutputType.SEPARATOR) {
        return <FieldsSeparator />;
    }

    if (type === OutputType.TITLE) {
        return <FieldTitle>{title}</FieldTitle>;
    }

    return (
        <InfoTip
            size="s"
            data-testid={dataTestId}
            data-cy={getDataAttribute('', uniqueIdentifier, 'infotip')}
            tipType={type?.toLowerCase() as TipTypes}
            {...props}
        />
    );
};
