import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import {
    RadioGroup,
    Select,
    SelectAutocomplete,
    SelectOption,
} from 'workmotion-design-system';

import { labelStyle } from 'dynamic-form/styled-components';
import { getDataAttribute } from 'helpers/get-data-attribute';
import {
    CollectionControlBase,
    CollectionItemType,
    UseField,
} from 'types/control-form';

interface CollectionControlProps extends CollectionControlBase {
    setEnablerValue: (updatedValue: boolean | string) => void;
    setEnabledFieldValue: (
        requestType: string,
        fieldValue: string | boolean | number | Date
    ) => void;
    useField?: UseField;
}

export const CollectionControl: React.FC<CollectionControlProps> = ({
    setEnablerValue,
    setEnabledFieldValue,
    ...props
}) => {
    const {
        items,
        type,
        customValue,
        id,
        label,
        placeholder,
        className,
        uniqueIdentifier,
        disabled,
        loading = false,
        useField,
        dataTestId,
    } = props;

    const [field, meta, helpers] = useField(id);

    const { value } = field;
    const { setTouched } = helpers;

    const showError = !!(meta.touched && meta.error);

    const updateEnabledComponents = useCallback(
        selected => {
            if (selected) {
                setEnablerValue(selected as string);
            }
        },
        [setEnablerValue]
    );

    if (type === CollectionItemType.DROPDOWN) {
        return (
            <div
                style={{ width: '100%' }}
                key={id}
                data-testid={`${dataTestId}-dropdown`}
            >
                <Select
                    {...field}
                    options={items as []}
                    name={label}
                    placeholder={placeholder}
                    value={disabled ? customValue : value?.value}
                    onBlur={() => setTouched(true)}
                    onChange={updateEnabledComponents}
                    showError={showError}
                    data-cy={getDataAttribute('', uniqueIdentifier, 'select')}
                />
            </div>
        );
    }

    if (
        // remove SEARCHSELECT after make sure no other places use it
        type === CollectionItemType.SEARCHSELECT ||
        type === CollectionItemType.SEARCHDROPDOWN
    ) {
        return (
            <div
                style={{ width: '100%' }}
                key={id}
                data-testid={`${dataTestId}-searchable_dropdown`}
            >
                <SelectAutocomplete
                    {...field}
                    name={label}
                    options={items as SelectOption[]}
                    onChange={updateEnabledComponents}
                    value={value?.value}
                    disabled={disabled}
                    loading={loading}
                    showError={showError}
                    data-cy={getDataAttribute(
                        '',
                        uniqueIdentifier,
                        'autocomplete-select'
                    )}
                />
            </div>
        );
    }

    if (type === CollectionItemType.RADIO) {
        return (
            <Box sx={className === 'in-row' ? labelStyle : null}>
                <RadioGroup
                    {...field}
                    options={items.map(elem => ({
                        id: `${id}-${elem.value}`,
                        name: `${id}-${elem.value}`,
                        label: elem.label,
                        value: elem.value,
                        'data-cy': getDataAttribute(
                            '',
                            uniqueIdentifier,
                            `${elem.value.toLowerCase()}-radio`
                        ),
                    }))}
                    theme="dark"
                    value={value?.value}
                    onChange={e => setEnablerValue(e.target.value)}
                    data-testid={`${dataTestId}-radio`}
                    row={className === 'in-row'}
                />
            </Box>
        );
    }

    return null;
};
