import React, { useCallback } from 'react';

import {
    InputControlBase,
    InputType,
    UseField,
    DynamicValues,
    UseFormikContext,
} from 'types/control-form';

import { UnsupportedField } from './unsupported-field';
import { MultiPickerDateComponent } from '../../design-elements/mui-date-picker/multi-date-range-component';

interface MultiInputControlProps extends InputControlBase {
    onBlur: () => void;
    useFormikContext?: UseFormikContext;
    useField?: UseField;
}

export const MultiInputControl: React.FC<MultiInputControlProps> = ({
    onBlur,
    useField,
    useFormikContext,
    ...props
}) => {
    const { type, id } = props;

    const [field, meta] = useField(id);

    const { setFieldValue, setFieldTouched } =
        useFormikContext<DynamicValues>();

    const { value, name } = field;

    const hasError = !!(meta.touched && meta.error);

    const onChangeHandle = useCallback(
        date => {
            setFieldTouched(name);

            setFieldValue(name, {
                ...value,
                values: date,
            });
        },
        [name, setFieldValue, value, setFieldTouched]
    );

    if (type === InputType.DATE_ONLY) {
        return (
            <MultiPickerDateComponent
                value={field.value?.values}
                onChange={onChangeHandle}
                hasError={hasError}
            />
        );
    }

    return <UnsupportedField />;
};
