import {
    OnboardingErrors,
    SchemaComponent,
    ControlBase,
    ControlTypeBase,
} from 'types/control-form';

import { getValuesFromSaved } from './initial-values/get-values-from-saved';
import { getValuesFromSchema } from './initial-values/get-values-from-schema';
import { InitialValues } from './types';
import { componentValidation } from './validation/components';

const getInitialValues = (
    schemaComponents: SchemaComponent[]
): InitialValues => {
    const savedComponents = schemaComponents.filter(
        (component: SchemaComponent) => component.value != null
    );

    if (savedComponents.length) {
        return getValuesFromSaved(savedComponents);
    }

    return getValuesFromSchema(schemaComponents as ControlTypeBase[]);
};

const getValidationSchema = (components: ControlBase[]): OnboardingErrors => {
    const stepValidation: OnboardingErrors = {};

    components.forEach((component: ControlBase) => {
        stepValidation[component.id] = componentValidation(component);
    });

    return stepValidation;
};

export { getInitialValues, getValidationSchema };
