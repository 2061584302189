import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import {
    labelStyles,
    inputStyles,
    errorMsgStyles,
    helperTextStyles,
    wrapperStyles,
} from '../styled-components';
import { BasicInputProps } from '../types';

export const BasicInput: React.FC<BasicInputProps> = ({
    label,
    placeholder,
    inputSize = 'fullWidth',
    showError: hasError = false,
    errorMessage,
    helperText,
    inputType,
    id,
    required,
    attrs = {},
    ...props
}: BasicInputProps) => {
    const onWheel = event => event.target.blur();

    return (
        <Box sx={wrapperStyles}>
            {label && (
                <Box
                    sx={labelStyles}
                    data-label-required={required}
                    {...attrs.label}
                >
                    {label}
                </Box>
            )}

            <TextField
                {...props}
                hiddenLabel
                onWheel={onWheel}
                onScroll={onWheel}
                variant="outlined"
                placeholder={placeholder}
                className={inputSize}
                error={hasError}
                id={id}
                sx={inputStyles}
                required={required}
                type={inputType}
                inputProps={{ ...props.inputProps, ...attrs.input }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={props.InputProps}
                helperText={
                    errorMessage && (
                        <Box sx={errorMsgStyles} {...attrs.errorMsg}>
                            {errorMessage}
                        </Box>
                    )
                }
            />

            {helperText && (
                <Box sx={helperTextStyles} {...attrs.helperText}>
                    {helperText}
                </Box>
            )}
        </Box>
    );
};
