import React, { FC, useEffect } from 'react';

import { FormField } from 'workmotion-design-system';

import { getInitialValue } from 'formik-setup/initial-values/get-values-from-schema';

import { RenderControl } from './render-control';
import {
    DynamicValues,
    UseFormikContext,
    UseField,
    ControlTypeBase,
    ControlType,
    OutputType,
} from '../types/control-form';

type ControlProps = ControlTypeBase & {
    onEnablerValueChanged?: (itemId: string, toEnable: boolean) => void;
    onCustomChange?: any;
    changeReadOnlyStatus?: any;
    useFormikContext: UseFormikContext;
    useField: UseField;
    fileUpload?: (files: File[]) => Promise<unknown[]>;
};

export const Control: FC<ControlProps> = controlProps => {
    const {
        label,
        id,
        required,
        enabled,
        componentType,
        useFormikContext,
        className,
        type,
    } = controlProps;

    const { touched, errors, values, setFieldValue } =
        useFormikContext<DynamicValues>();

    const name = id;

    const isTouched = touched[name];
    const error = errors[name] as string;
    const showError = !!(isTouched && error);

    useEffect(() => {
        if (
            componentType === ControlType.OUTPUT &&
            type !== OutputType.SEPARATOR &&
            type !== OutputType.TITLE
        ) {
            return;
        }

        if (!values[id] && enabled) {
            setFieldValue(id, getInitialValue(controlProps));
        }
    }, [values, id, enabled, setFieldValue, componentType, controlProps, type]);

    if (enabled)
        return (
            <div
                style={
                    className === 'two-items-field'
                        ? { width: '48%' }
                        : { width: '100%' }
                }
            >
                <FormField
                    label={label}
                    required={required}
                    showError={showError}
                    errorMessage={
                        error?.charAt(0).toUpperCase() +
                        error?.slice(1).toLocaleLowerCase()
                    }
                >
                    <RenderControl {...controlProps} />
                </FormField>
            </div>
        );

    return null;
};
