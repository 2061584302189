import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';

import { CollectionControlBase, CollectionItemType } from 'types/control-form';

const required = function (
    this: TestContext<AnyObject>,
    val,
    { type }: CollectionControlBase
) {
    if (type === CollectionItemType.CHECKBOX) {
        return (
            !!val?.value ||
            this.createError({
                message: 'You must first approve the data provided',
            })
        );
    }

    return !!val?.value;
};

const collectionValidation = { required };

export { collectionValidation };
