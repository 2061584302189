import { ControlType } from 'types/control-form';

const maxLength = (componentType: ControlType, val) => {
    if (componentType === ControlType.TEXT_AREA) {
        return String(val?.value).length <= 1024;
    }

    return true;
};

const textAreaValidation = { maxLength };

export { textAreaValidation };
