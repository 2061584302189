import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';

import { en } from 'localization';

import { compareDates } from './date-helper';
import { DateRange } from './date-range';
import { ErrorMessageStyles } from './styled-components';
import { DateRangePickerProps } from './types';

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    onChange,
    hasError = false,

    startDate,
    endDate,
    ...restProps
}) => {
    const {
        datePickers: { startEndDateCompareError },
    } = en;

    const [start, setStart] = useState<Date>(
        startDate ? new Date(startDate) : null
    );

    const [error, setError] = useState<string>('');

    const [end, setEnd] = useState<Date>(endDate ? new Date(endDate) : null);

    const onChangeValueHandle = useCallback(
        (newStartDate, newEndDate) => {
            if (compareDates(newStartDate, newEndDate)) {
                onChange(newStartDate, newEndDate);
            } else {
                setError(startEndDateCompareError);
            }
        },
        [onChange, setError, startEndDateCompareError]
    );

    const handleStartDateChange = useCallback(
        date => {
            setStart(date);

            onChangeValueHandle(date, end);
        },
        [setStart, end, onChangeValueHandle]
    );

    const handleEndDateChange = useCallback(
        date => {
            setEnd(date);

            onChangeValueHandle(start, date);
        },
        [setEnd, start, onChangeValueHandle]
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DateRange
                start={start || null}
                end={end || null}
                hasError={!!hasError || !!error}
                setError={setError}
                changeEnd={handleEndDateChange}
                changeStart={handleStartDateChange}
                {...restProps}
            />

            {error && (
                <Box sx={ErrorMessageStyles} data-testid="error-message">
                    {error}
                </Box>
            )}
        </div>
    );
};
