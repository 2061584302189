import React from 'react';

import { BasicInput } from './BasicInput';
import { PasswordInput } from './PasswordInput';
import { InputFieldsProps } from './types';
import { UnitInput } from './UnitInput';

export const MuiInputField: React.FC<InputFieldsProps> = ({
    type,
    adornmentPosition,
    id,
    required,
    ...props
}) => {
    switch (type) {
        case 'password':
            return (
                <PasswordInput
                    id={id}
                    adornmentPosition={adornmentPosition}
                    required={required}
                    {...props}
                />
            );
        case 'unit':
            return (
                <UnitInput
                    id={id}
                    adornmentPosition={adornmentPosition}
                    required={required}
                    {...props}
                />
            );
        default:
            return (
                <BasicInput
                    id={id}
                    inputType={type}
                    required={required}
                    {...props}
                />
            );
    }
};
