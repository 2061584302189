import React, { useEffect, useState } from 'react';

import {
    palette,
    DeleteForeverIcon,
    DescriptionIcon,
} from 'workmotion-design-system';

import { FileName, StyledLineContainer } from './styled-components';

const FilenamePreview: React.FC<{
    onDeleteFile: (name: string) => void;
    files: any[];
}> = ({ onDeleteFile, files }) => {
    const [previewFileNames, setpreviewFileNames] = useState<Array<string>>([]);

    useEffect(() => {
        if (files?.length && Array.isArray(files)) {
            setpreviewFileNames(
                files.map(file => {
                    if (file?.fileName) {
                        return file.fileName;
                    }

                    return file.name;
                })
            );
        } else {
            setpreviewFileNames([]);
        }
    }, [files]);

    return (
        <>
            {files?.length > 0 &&
                previewFileNames.map((filename: string) => (
                    <StyledLineContainer
                        key={filename}
                        data-testid="filename-container"
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                lineHeight: '1rem',
                            }}
                        >
                            <DescriptionIcon
                                style={{
                                    color: palette.greyscale.UCLABlue,
                                    fontSize: '1rem',
                                    marginInlineEnd: '.5rem',
                                }}
                            />

                            <FileName data-cy="ee-expense-uploaded-file-name">
                                {filename}
                            </FileName>
                        </div>

                        <DeleteForeverIcon
                            style={{
                                color: palette.semantic.red,
                                fontSize: '1rem',
                                cursor: 'pointer',
                            }}
                            type="button"
                            onClick={() => onDeleteFile(filename)}
                            data-testid="delete-btn"
                        />
                    </StyledLineContainer>
                ))}
        </>
    );
};

export { FilenamePreview };
