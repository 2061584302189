import React, { useCallback } from 'react';

import { en } from 'localization';

import { isValidIsoDate } from './date-helper';
import { DatePicker } from './mui-datepicker';
import { DateRangeProps } from './types';

export const DateRange: React.FC<DateRangeProps> = ({
    name,
    hasError = false,
    onBlur,
    dataCy,
    id,
    start,
    end,
    setError,
    changeEnd,
    changeStart,
    ...restProps
}) => {
    const {
        datePickers: { invalidDateError },
    } = en;

    const checkInvalidDate = useCallback(
        (date: Date): boolean => {
            if (date === null || isValidIsoDate(date)) {
                setError('');

                return true;
            }

            setError(invalidDateError);

            return false;
        },
        [invalidDateError, setError]
    );

    const handleStartDateChange = useCallback(
        date => {
            if (checkInvalidDate(date)) {
                changeStart(date);
            }
        },
        [changeStart, checkInvalidDate]
    );

    const handleEndDateChange = useCallback(
        date => {
            if (checkInvalidDate(date)) {
                changeEnd(date);
            }
        },
        [changeEnd, checkInvalidDate]
    );

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '0.75rem',
            }}
        >
            <DatePicker
                key={`${name}-start`}
                value={start}
                onChange={handleStartDateChange}
                hasError={hasError}
                id="start-date-field"
                dataCy="start-date-picker-field"
                iconDataCy="start-date-icon"
                {...restProps}
            />

            <DatePicker
                key={`${name}-end`}
                value={end}
                onChange={handleEndDateChange}
                minDate={start}
                hasError={hasError}
                defaultCalendarMonth={start}
                id="end-date-field"
                dataCy="end-date-picker-field"
                iconDataCy="end-date-icon"
                {...restProps}
            />
        </div>
    );
};
