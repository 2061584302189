import {
    ComponentRequestType,
    ControlType,
    FieldValue,
    MultiValueComponent,
    SchemaComponent,
    SingleValueComponent,
} from 'types/control-form';

import { getInitialValue } from './get-values-from-schema';
import { InitialValues } from '../types';

const getSavedMultiValue = ({
    id,
    value,
}: SchemaComponent): MultiValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.MultiValue,
    values: value,
});

const getSavedSingleValue = ({
    id,
    value,
}: SchemaComponent): SingleValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.SingleValue,
    value,
});

const getSavedDocumentUploader = ({
    id,
    value,
}: SchemaComponent): MultiValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.DocumentUploader,
    values: value,
});

const getSavedMultiSelect = ({
    id,
    value,
}: SchemaComponent): MultiValueComponent => ({
    id,
    componentRequestType: ComponentRequestType.MultiValue,
    values: (value as string)?.length ? (value as string).split(', ') : [],
});

const getSavedValue = (schemaComponent: SchemaComponent): FieldValue => {
    const { componentType } = schemaComponent;

    if (
        componentType === ControlType.MULTI_CHECKBOX ||
        componentType === ControlType.MULTI_INPUT
    ) {
        return getSavedMultiValue(schemaComponent);
    }

    if (componentType === ControlType.DOCUMENT_UPLOADER) {
        return getSavedDocumentUploader(schemaComponent);
    }

    if (componentType === ControlType.MULTI_SELECT) {
        return getSavedMultiSelect(schemaComponent);
    }

    if (componentType.toLowerCase() === ControlType.UPLOAD) {
        return {
            id: schemaComponent.id,
            componentRequestType: schemaComponent.requestType,
            ...schemaComponent,
        } as FieldValue;
    }

    return getSavedSingleValue(schemaComponent);
};

const getValuesFromSaved = (
    schemaComponents: Array<SchemaComponent>
): InitialValues => {
    const initialValues: InitialValues = {};

    schemaComponents
        .filter(component => component.componentType !== ControlType.OUTPUT)
        .forEach((component: SchemaComponent) => {
            const name = component.id;

            const savedValue = <SingleValueComponent | MultiValueComponent>(
                getSavedValue(component)
            );

            const isSavedComponentOptional =
                savedValue.value === undefined &&
                !(<MultiValueComponent>savedValue).values;

            if (isSavedComponentOptional) {
                initialValues[name] = getInitialValue(component);
            } else {
                initialValues[name] = <FieldValue>getSavedValue(component);
            }
        });

    return initialValues;
};

export { getSavedValue, getValuesFromSaved };
