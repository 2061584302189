import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import {
    adornmentStyles,
    IconBtnStyles,
    adornmentColor,
} from '../styled-components';
import { InputFieldsProps } from '../types';

interface AdornmentProps {
    showPassword: boolean;
    setShowPassword: (show: boolean) => void;
    adornmentPosition: InputFieldsProps['adornmentPosition'];
}

export const Adornment: React.FC<AdornmentProps> = ({
    showPassword,
    setShowPassword,
    adornmentPosition,
}) => {
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <InputAdornment position={adornmentPosition} sx={adornmentStyles}>
            <IconButton
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge={adornmentPosition}
                sx={IconBtnStyles}
                className={adornmentPosition}
            >
                {showPassword ? (
                    <Visibility fontSize="small" style={adornmentColor} />
                ) : (
                    <VisibilityOff fontSize="small" style={adornmentColor} />
                )}
            </IconButton>
        </InputAdornment>
    );
};
