import React, { useCallback } from 'react';

import { Checkbox } from 'workmotion-design-system';

import { MultiCheckboxWrapper } from 'dynamic-form/styled-components';
import {
    MultiCheckboxControlBase,
    DynamicValues,
    UseField,
    UseFormikContext,
} from 'types/control-form';

interface MultiCheckboxProps extends MultiCheckboxControlBase {
    dataTestId?: string;
    useFormikContext?: UseFormikContext;
    useField?: UseField;
}

export const MultiCheckboxControl: React.FC<MultiCheckboxProps> = ({
    dataTestId,
    useField,
    ...props
}) => {
    const { items, useFormikContext, id } = props;

    const [field] = useField(id);

    const {
        name,
        value,
        value: { values },
    } = field;

    const { setFieldValue, setFieldTouched } =
        useFormikContext<DynamicValues>();

    const schemaValues = items.map(item => item.value);

    const handleChange = useCallback(
        (item: string) => () => {
            const checked = values?.includes(item);
            const updatedValues = [...values, item];

            const uncheckedValues = [...values.filter(val => val !== item)];

            const checkedValues = schemaValues.filter(val =>
                updatedValues?.includes(val)
            );

            setFieldTouched(name);

            setFieldValue(name, {
                ...value,
                values: checked ? uncheckedValues : checkedValues,
            });
        },
        [name, setFieldTouched, setFieldValue, value, values, schemaValues]
    );

    return (
        <MultiCheckboxWrapper data-testid={dataTestId}>
            {items.map(item => (
                <Checkbox
                    key={item.value}
                    id={item.value}
                    checked={values.includes(item.value)}
                    onChange={handleChange(item.value)}
                >
                    <span>{item.label}</span>
                </Checkbox>
            ))}
        </MultiCheckboxWrapper>
    );
};
