const readFileAsDataURL = (blob: Blob): Promise<FileReader['result']> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                resolve(reader.result);
            },
            false
        );

        reader.addEventListener('error', reject);
        reader.readAsDataURL(blob);
    });

export default readFileAsDataURL;
